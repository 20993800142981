<template>
  <el-upload
    :show-file-list="false"
    :headers="headers"
    :action="action"
    :data="data"
    :name="name"
    :before-upload="beforeUpload"
    :on-progress="onProgress"
    :on-success="onSuccess"
    drag
  >
    <slot >  </slot>
  </el-upload>
</template>

<script setup>

import {computed,getCurrentInstance,defineEmits,ref,onMounted} from 'vue'
const { proxy } = getCurrentInstance()
const props = defineProps({
    action: {
        type: String,
        required: true
    },
    headers: {
        type: Object,
        default: () => {}
    },
    data: {
        type: Object,
        default: () => {}
    },
    name: {
        type: String,
        default: 'file'
    },
    url: {
        type: String,
        default: ''
    },
    size: {
        type: Number,
        default: 4
    },
    proposal: {
      type: String,
      default: '300*500'
    },
    ext: {
        type: Array,
        default: () => ['jpg', 'png', 'gif', 'bmp','jpeg']
    }
})

const emit = defineEmits(['update:url', 'on-success'])

const uploadData = ref({
    imageViewerVisible: false,
    progress: {
        preview: '',
        percent: 0
    }
})

const actionsStyle = computed(() => {
  if(props.width < 100){
    return `width:${props.width}px;height:${props.height}px;`
  }
  return ''
})
// 预览
function preview() {
    uploadData.value.imageViewerVisible = true
}
// 关闭预览
function previewClose() {
    uploadData.value.imageViewerVisible = false
}
// 移除
function remove() {
    emit('update:url', '')
}
function beforeUpload(file) {
    const fileName = file.name.split('.')
    const fileExt = fileName[fileName.length - 1]
    const isTypeOk = props.ext.indexOf(fileExt) >= 0
    const isSizeOk = file.size / 1024 / 1024 < props.size
    if (!isTypeOk) {
        proxy.$message.error(`上传图片只支持 ${ props.ext.join(' / ') } 格式！`)
    }
    if (!isSizeOk) {
        proxy.$message.error(`上传图片大小不能超过 ${props.size}MB！`)
    }
    if (isTypeOk && isSizeOk) {
        uploadData.value.progress.preview = URL.createObjectURL(file)
    }
    return isTypeOk && isSizeOk
}
function onProgress(file) {
    uploadData.value.progress.percent = ~~file.percent
}
function onSuccess(res) {
    uploadData.value.progress.preview = ''
    uploadData.value.progress.percent = 0
    if(res.code === 0){
      emit('on-success', res.data)
    }

}
</script>

<style lang="scss" scoped>

:deep(.el-upload) {
    .el-upload-dragger {
        width: auto;
        height: 100%;
        padding:0px;
        border: none;
        &.is-dragover {
            border-width: 1px;
        }
        .el-image {
            display: block;
            .image-slot {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                color: #909399;
                background-color: transparent;
                i {
                    font-size: 30px;
                }
            }
        }
        .progress {
            position: absolute;
            top: 0;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgb(0 0 0 / 20%);
            }
            .el-progress {
                z-index: 1;
                @include position-center(xy);
                .el-progress__text {
                    color: #fff;
                }
            }
        }
    }
}
</style>
