<template>
  <el-dropdown class="quick-dropdown"  @command="handleCommand">

    <el-button class="dropdown-btn" :size="size" type="text">{{title}}
      <quick-icon icon="el-icon-ArrowDown"></quick-icon></el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item :command="action.value" v-for="(action,index) in options" :key="index">{{action.name}}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>

  import {ref, inject,  provide,onMounted,watch} from 'vue';
  import ElDropdown from "element-plus"
  export default {
    name: 'QuickDropdown',
    extends: {
      ElDropdown,
    },
    props: {
      title:{
        type:String,
        default:"title"
      },
      size:{
        type:String,
        default:"small"
      },
      field:{
        type:String,
        default:"field"
      },
      options:{
        type:Array,
        default:() => [],
      }
    },
    setup(props, {slots,emit}) {

      const handleCommand = function (command){
        let data = {
          [props.field]:command
        };
        emit('action',data,data)
      }
      return {
        handleCommand
      }
    },

  };

</script>

<style lang="scss" scoped>
  .quick-dropdown{
    vertical-align: bottom;
    .dropdown-btn{
      padding:2px;
    }
  }
</style>
