import { createStore, createLogger } from 'vuex'

const files = require.context('./modules', false, /\.js$/);
const modules = {}
files.keys().forEach((key) => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

export default createStore({
    modules: modules,
    strict: false,
    plugins: !0 ? [createLogger()] : []
})
