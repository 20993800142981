<template>
  <div>
    <div style="max-width: 720px;">
      <div class="dir-left-nowrap" v-if="list && list.length">
        <el-card class="area-box" shadow="never">
          <div :id="'#id_' + item.value" class="el-checkbox item-box" v-for="(item, index) in list" :key="index"
               @click="selectProvince(index)">
            <el-checkbox v-if="!radio" @change="pickerChange(item)"
                         v-model="item.checked" :indeterminate="item.indeterminate"
                         :disabled="item.unchecked || all">
              <span style="display: none" class="el-checkbox__label">{{ item.label }}</span>
            </el-checkbox>

            <el-radio style="margin-right: 0" v-else @change="pickerChange(item)" v-model="item.indeterminate"
                      :label="true">
              <span style="display: none">{{ item.label }}</span>
            </el-radio>

            <span class="el-checkbox__label" :class="pIndex === index ? 'check-active':''">{{ item.label }}</span>
          </div>
        </el-card>
        <el-card class="area-box" shadow="never"
                 v-if="list[pIndex].children && list[pIndex].children.length > 0">
          <div :id="'#id_' + item.value" v-for="(item, index) in list[pIndex].children" :key="index" class="el-checkbox item-box"
               @click="cIndex = index"
          >
            <el-checkbox v-if="!radio" @change="pickerChange(item)"
                         v-model="item.checked" :indeterminate="item.indeterminate"
                         :disabled="item.unchecked || all">
              <span style="display: none" class="el-checkbox__label">{{ item.label }}</span>
            </el-checkbox>
            <el-radio style="margin-right: 0" v-else @change="pickerChange(item)" v-model="item.indeterminate"
                      :label="true">
              <span style="display: none">{{ item.label }}</span>
            </el-radio>
            <span class="el-checkbox__label" :class="cIndex === index ? 'check-active':''">{{ item.label }}</span>
          </div>
        </el-card>
        <el-card class="area-box" shadow="never"
                 v-if="list[pIndex].children[cIndex].children && list[pIndex].children[cIndex].children.length > 0">
          <div :id="'#id_' + item.value" v-for="(item, index) in list[pIndex].children[cIndex].children" :key="index" class="item-box el-checkbox"
               @click="dIndex = index">
            <el-checkbox v-if="!radio" @change="pickerChange(item)"
                         v-model="item.checked"
                         :disabled="item.unchecked || all">
              <span style="display: none" class="el-checkbox__label">{{ item.label }}</span>
            </el-checkbox>
            <el-radio style="margin-right: 0" v-else @change="pickerChange(item)" v-model="item.checked" :label="true">
              <span style="display: none">{{ item.label }}</span>
            </el-radio>
            <span class="el-checkbox__label" :class="dIndex === index ? 'check-active':''">{{ item.label }}</span>
          </div>
        </el-card>
      </div>
      <div>

      </div>
      <div style="padding-top: 12px;" class="dir-right-nowrap">
        <el-button style="margin-left: 10px;" type="primary" @click="handleConfirm">{{__('Confirm')}}</el-button>
        <el-button @click="handleCancel">{{__('Cancel')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed} from 'vue';

export default {
  name: "SelectArea",
  props: {
    radio: {
      type: Boolean,
      default: false
    },
    all: Boolean,
    disable: Array,
    selected: Array,
    options:Array,
  },
  setup(props,{emit}) {
    const list = ref([])
    const pIndex = ref(0)
    const cIndex = ref(0)
    const dIndex = ref(0)
    const tempList = ref([])
    const first = ref(true)


    watch(() => props.disable,() =>{
      initData()
    },{deep:true})
    watch(() => props.selected,() =>{
      initData()
    },{deep:true})
    const initDistrictData = ref([])
    onMounted(() => {
      initDistrictData.value = props.options
      initData()
    })

    const initData = () => {
      if(!props.options  || !props.options.length){
          return
      }
      let areaList = checkList(initDistrictData.value, props.disable, props.selected);
      areaList = setListCheckedd(areaList, 1);
      areaList = setListCheckedd(areaList, 0);
      list.value = JSON.parse(JSON.stringify(areaList));
      if (props.radio) {
        for (let i in list.value) {
          if (list.value[i].indeterminate) {
            pIndex.value = i
            if (first.value) {
              let id = '#id_' + list.value[i].value;
              setTimeout(() => {
                first.value = false;
                let element = document.getElementById(id);
                element.scrollIntoView();
              })
            }
            for (let ci in list.value[i].children) {
              if (list.value[i].children[ci].indeterminate) {
                cIndex.value = ci
              }
            }
          }
        }
      }
    }

    // 判断哪些省市区已经选择
    const checkList = (initAreaData, disable, selected) => {
      if (typeof initAreaData == 'undefined') {
        return [];
      }
      for (let i in initAreaData) {
        initAreaData[i].checked = false;
        initAreaData[i].unchecked = false;
        initAreaData[i].indeterminate = false;
        if (typeof initAreaData[i].children != 'undefined') {
          initAreaData[i].children = checkList(initAreaData[i].children, disable, selected);
        }

        // 设置禁选
        if (typeof disable != 'undefined'  && disable.length) {
          if (disable.includes(initAreaData[i].value)) {
            initAreaData[i].unchecked = true;
          }
        }

        // 设置已选
        if (typeof selected != 'undefined' && selected.length) {
          if (selected.includes(initAreaData[i].value)) {
            initAreaData[i].checked = true;
            initAreaData[i].unchecked = false;
          }
        }
      }
      return initAreaData;
    }

    // 重新赋值已经选择的省市区level == 1父级影响子级||level == 0子级影响父级
    const setListCheckedd = (list, level = 1) => {
      if (typeof list == 'undefined') {
        return list;
      }
      for (let i in list) {
        if (typeof list[i].children != 'undefined') {
          if(level == 0) {
            list[i].children = setListCheckedd(list[i].children, 0);
          }
          let unchecked = false;
          let checkCount = 0;
          let indeterminateCount = 0;
          for (let j in list[i].children) {
            if (list[i].unchecked && level == 1) {
              list[i].children[j].unchecked = true;
            }
            if (list[i].children[j].unchecked) {
              unchecked = true;
            }
            if (list[i].checked) {
              list[i].children[j].checked = true;
            }
            if(list[i].children[j].checked) {
              checkCount++;
            }
            if(list[i].children[j].indeterminate) {
              indeterminateCount++;
            }
          }
          if (unchecked && level == 0) {
            list[i].unchecked = true;
          }
          if(list[i].children.length == checkCount) {
            list[i].checked = true;
          } else if (checkCount > 0) {
            list[i].checked = false;
            list[i].indeterminate = true;
          } else {
            list[i].checked = false;
            if(indeterminateCount > 0) {
              list[i].indeterminate = true;
            } else {
              list[i].indeterminate = false;
            }
          }
          if(level == 1) {
            list[i].children = setListCheckedd(list[i].children, 1);
          }
        }
      }
      return list;
    }
    const setListChecked = (list,pChecked,pUnchecked) => {

      let checkCount = 0;
      let indeterminateCount = 0;
      let unchecked = 0;
      for (let i in list) {
        if (typeof list[i].children != 'undefined') {
          let childList = setListChecked(list[i].children,list[i].checked,list[i].unchecked);

          if(childList.checkCount === list[i].children.length){
            list[i].checked = true;
          }else if (childList.checkCount > 0) {
            list[i].checked = false;
            list[i].indeterminate = true;
            indeterminateCount++;
          }else{
            list[i].checked = false;
            if (childList.indeterminateCount > 0) {
              list[i].indeterminate = true;
              indeterminateCount++;
            } else {
              list[i].indeterminate = false;
            }
          }

          if(childList.unchecked){
            list[i].unchecked = true
          }
          list[i].children = childList.children
        }

        if(list[i].unchecked){
          unchecked = true
        }

        // 父节点禁用，子节点也不可用
        if(pUnchecked){
          list[i].unchecked = true
        }
        // 父节选中,子节点默认选中
        if(pChecked){
          list[i].checked = true
        }

        if (list[i].checked) {
          checkCount++;
        }
      }
      return {
        children: list,
        unchecked: unchecked,
        checkCount: checkCount,
        indeterminateCount: indeterminateCount
      };
    }


    const computedList = (list) => {
      let newList = [];
      let checkCount = 0;
      let indeterminateCount = 0;
      for (let i in list) {
        if (typeof list[i].children != 'undefined') {
          let childList = computedList(list[i].children);
          if (childList.indeterminateCount > 0) {
            list[i].checked = false;
            list[i].indeterminate = true;
            indeterminateCount++;
            newList = newList.concat(childList.newList);
          } else if (childList.checkCount < list[i].children.length) {
            list[i].checked = false;
            if (childList.checkCount > 0) {
              list[i].indeterminate = true;
              indeterminateCount++;
              newList = newList.concat(childList.newList);
            } else {
              list[i].indeterminate = false;
            }
          } else {
            if (typeof props.disable == 'undefined' || !props.disable.includes( list[i].value)) {
              list[i].checked = true;
            }
            list[i].indeterminate = false;
          }
        }
        if (list[i].checked) {
          checkCount++;
          newList.push(list[i]);
        }
      }
      return {
        newList: newList,
        checkCount: checkCount,
        indeterminateCount: indeterminateCount
      };
    }


    const selectProvince = (index) => {
      pIndex.value = index
      cIndex.value = 0
      dIndex.value = 0
    }

    const changeAllList = (list, checked) => {
      if (typeof list != 'undefined') {
        for (let i in list) {
          if (typeof list[i].children != 'undefined') {
            list[i].children = changeAllList(list[i].children, checked);
          }

          if (typeof props.disable == 'undefined' || !props.disable.includes( list[i].value)) {
            list[i].checked = checked;
          }

        }
      }
      return list;
    }

    const pickerChange = (item) => {
      if (props.radio) {
        let provinceItem ={
          value: '',
          name: ''
        }
        let cityItem ={
          value: '',
          name: ''
        }
        let districtItem = {
          value: '',
          name: ''
        }
        for (let province of list.value) {
          province.indeterminate = false;
          if (item.pid == 1) {
            if (item.value == province.value) {
              province.indeterminate = true;
              provinceItem.value = province.value;
              provinceItem.label = province.label;
            }
          } else {
            if (item.level == 2 && item.pid == province.value) {
              province.indeterminate = true;
              provinceItem.value = province.value;
              provinceItem.label = province.label;
            }
          }
          for (let city of province.children) {
            city.indeterminate = false;
            if (item.level == 2) {
              if (item.value == city.value) {
                city.indeterminate = true;
                cityItem.value = city.value;
                cityItem.label = city.label;
              }
            }
            if (item.level == 3) {
              if (item.pid == city.value) {
                city.indeterminate = true;
                cityItem.value = city.value;
                cityItem.label = city.label;
                province.indeterminate = true;
                provinceItem.value = province.value;
                provinceItem.label = province.label;
              }
            }
            for (let district of city.children) {
              district.checked = false;
              if (item.level == 3) {
                if (item.value == district.value) {
                  district.checked = true;
                  districtItem.value = district.value;
                  districtItem.label = district.label;
                  // console.log('--------------dd', [provinceItem, cityItem, districtItem])
                  // this.$emit('selected', [provinceItem, cityItem, districtItem]);
                }
              }
            }
          }
        }
      } else {
        changeAllList(item.children, item.checked);
        let temp = computedList(list.value);

        tempList.value = temp.newList.sort(function (x,y) {
          if(x.level == y.level){
            if(x.value > y.value){
              return 1
            }
            return -1
          }else if(x.level > y.level){
            return 1
          }else{
            return -1
          }
        });
        // console.log('--------------tempList', tempList.value)

        // this.$emit('selected', this.tempList, this.params);
      }
    }


    const handleConfirm = (data) => {
      emit('confirm',tempList.value.map((item) => {
          return item.value
      }))
    }
    const handleCancel = () => {
      emit('cancel',{})
    }
    return {
      list,
      pIndex,
      cIndex,
      dIndex,
      tempList,
      selectProvince,
      pickerChange,
      changeAllList,
      handleConfirm,
      handleCancel,
    }
  }
}
</script>

<style scoped lang="scss">

.area-box::-webkit-scrollbar {
  width: 8px;
}

.area-box {
  height: 250px;
  overflow-y: auto;
  margin-right: 10px;
  width: 250px;

  .check-active {
    color: #79bbff;
  }

  .item-box {
    margin: 0px 2px 2px;
    display: block;
    .el-checkbox__label{
      white-space:pre-wrap;
    }
  }

}
</style>
