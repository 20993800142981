<template>
  <div class="page">
    <page-header :title="title" :backAction="backAction" :description="description" v-if="showHeader">
      <template #content>
        <slot name="description">
          <div class="header-content">{{ description }}</div>
        </slot>
        <slot name="tools"> </slot>
      </template>
    </page-header>
    <page-main :customStyle="pageStyle">
      <slot />
    </page-main>
  </div>
</template>

<script>

export default {
  name: 'QuickPage',
  props: {
    title: String,
    description: String,
    backAction:Object,
    showHeader: {
      type: Boolean,
      default: true
    },
    pageStyle:{
      type:Object,
      default:() =>{}
    },
  },
  data() {
    return {
      activeName: ''
    }
  },
  computed: {},
  watch: {},
  async created() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">

  .header-content{
    padding-bottom: 15px;
  }


</style>
