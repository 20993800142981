<template>
  <div
    class="switch-content"
    :class="emitKey"
  >
    <json-render v-if="show" :render-data="activeCom"/>
    <json-render v-else :render-data="inactiveCom" />
  </div>
</template>

<script>
import {onMounted, ref, inject, getCurrentInstance,computed} from "vue";
import {contrasts} from "@/components/Form/Composition/FormField";
export default {
  name: 'SwitchContent',
  props: {
    emitKey:{
      type:String,
      default:'when',
    },
    inactive:{
      type:[Object,String],
      default:''
    },
    active:{
      type:[Object,String],
      default:''
    },
    emit: {
      type: Array,
      default: () => []
    },
  },
  setup(props) {

    const groupForm = inject('groupForm', {});
    const bus = inject('formBus');
    const show = ref(true);

    const inactiveCom = computed(() => {
        if(typeof props.inactive === 'string'){
          return {
            component:'span',
            props:{},
            children: props.inactive
          }
        }
        return props.inactive
    })

    const activeCom = computed(() => {
      if(typeof props.active === 'string'){
        return {
          component:'span',
          props:{},
          children: props.active
        }
      }
      return props.active
    })
    /**
     * 对外提供事件驱动
     */
    const onEvent = () => {

      // 触发emit事件
      if (props.emit && props.emit.length) {

        props.emit.forEach((data) => {
            const { name, condition, option, event, yes, no } = data;
            groupForm.bus.on(name ,(eventData) => {
              const {form,action,data} = eventData
              if(action !== event){
                return
              }
              const res = contrasts(condition, option, data);
              const actionStr = res ? yes : no;
              if(actionStr === "show"){
                show.value = true
              }else if(actionStr === "hidden"){
                show.value = false
              }
            })
          }
        );
      }
    };

    onMounted(() => {
      onEvent();
    });


    return {
      show,
      inactiveCom,
      activeCom
    }
  },
}
</script>
<style scoped>

</style>
